import { gql } from '@apollo/client';

export const ROAD_DISTANCE = gql`
  query RoadDistance($origin: GeoCoordinate!, $destination: GeoCoordinate!, $measurementSystem: String!, $arrivalDateTime: String, $departureDateTime: String) {
    roadDistance(origin: $origin, destination: $destination, measurementSystem: $measurementSystem, arrivalDateTime: $arrivalDateTime, departureDateTime: $departureDateTime) {
      distance
      durationInSeconds
      unit
    }
  }
`;
