import './sidebar.scss';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSessionStorage } from '../../hooks/sessionStorage/useSessionStorage';
import { useBookingContext } from '../../hooks/useBookingContext';
import { useLocalize } from '../../hooks/useLocalize';
import { useQueryGetRoadDistance } from '../../queries/geo/hooks/useQueryGetRoadDistance';
import { formatTimeInterval } from '../../utils/utils';
import { BookingSummary } from '../bookingSummary/bookingSummary';
import { HeaderLogo } from '../headerLogo/HeaderLogo';

export const Sidebar: React.FC<any> = ({ sessionData }: any) => {
  const { locale } = useLocalize();
  const [transferData, setTransferData] = useState({}) as any;
  const [queryData, setQueryData] = useState({}) as any;
  const location = useLocation();
  const { preferredCurrencyCode } = useBookingContext();
  const [, setEstimatedDurationInSession] = useSessionStorage('estimatedDuration');
  const { refetch: refetchRoadDistance } = useQueryGetRoadDistance({
    skip: true,
    variables: {},
  });

  const params = new URLSearchParams(window.location.search);

  const onOriginAndDestinationResolved = (origin: any, destination: any) => {

    const promises = [
      refetchRoadDistance({
        origin,
        destination,
        measurementSystem: 'METRIC',
        departureDateTime: queryData?.directionality?.includes('INBOUND') ? queryData?.inboundPickup : queryData?.directionality?.includes('OUTBOUND') ? queryData?.outboundPickup : undefined,
      }),
    ];

    if (queryData.isRoundTrip === 'true') {
      promises.push(
        refetchRoadDistance({
          origin: destination,
          destination: origin,
          measurementSystem: 'METRIC',
          departureDateTime: queryData?.directionality?.includes('OUTBOUND') ? queryData?.inboundPickup : queryData?.directionality?.includes('INBOUND') ? queryData?.outboundPickup : undefined,
        }),
      );
    }

    Promise.all(promises)
      .then(([response, roundTripResponse]: any) => {
        const duration = formatTimeInterval(response.data.roadDistance?.durationInSeconds, locale);
        const distance = `${Math.round((response.data.roadDistance?.distance + Number.EPSILON) * 10) / 10} km`;

        transferData.distance = distance;
        transferData.duration = duration;

        if (queryData.isRoundTrip === 'true') {
          const returnDuration = `${Math.round(roundTripResponse.data.roadDistance?.durationInSeconds / 60)} min`;
          const returnDistance = `${Math.round((roundTripResponse.data.roadDistance?.distance + Number.EPSILON) * 10) / 10} km`;
          transferData.returnDistance = returnDistance;
          transferData.returnDuration = returnDuration;
        }

        setTransferData(transferData);
        setEstimatedDurationInSession(duration);
      });
  };

  const getQueryData = () => {
    const destinationParam = params.get('destination');
    const destinationObj = destinationParam ? JSON.parse(destinationParam) : null;
    const queryData: any = {
      partnerId: window.tz_globalConfigs?.partnerId,
      placeId: (params.get('placeId') !== 'undefined' && params.get('placeId') !== 'null') ? params.get('placeId') : null,
      hubId: params.get('hubId'),
      hubType: params.get('hubType'),
      hubName: params.get('hubName'),
      hubNameSecondary: params.get('hubNameSecondary'),
      vehicleCategory: params.get('vehicleCategory'),
      vehicleModels: params.get('vehicleModels'),
      directionality: params.get('directionality'),
      price: params.get('totalPrice') ? params.get('totalPrice') : params.get('price'),
      initialPrice: params.get('initialPrice'),
      pricePerPassenger: params.get('pricePerPassenger'),
      travelAddons: params.get('travelAddons') ? JSON.parse(params.get('travelAddons') as string) : null,
      destination: destinationObj,
      ...(!params.get('adultPassengers') ? { passengers: Number(params.get('passengers')) } : { adultPassengers: Number(params.get('adultPassengers')) }),
      ...(params.get('childPassengers') && { childPassengers: Number(params.get('childPassengers')) }),
      ...(params.get('infantPassengers') && { infantPassengers: Number(params.get('infantPassengers')) }),
      luggage: Number(params.get('luggage')) || 2,
      inboundPickup: params.get('inboundPickup'),
      outboundPickup: params.get('outboundPickup'),
      hubLat: Number(params.get('hubLat')),
      hubLng: Number(params.get('hubLng')),
      isRoundTrip: params.get('isRoundTrip'),
      preferredCurrencyCode: preferredCurrencyCode,
    };
    return queryData;
  };

  useEffect(() => {
    const queryData = getQueryData();
    setTransferData(transferData);
    setQueryData(queryData);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(sessionData).length) {
      setQueryData(sessionData);
    }
    // eslint-disable-next-line
  }, [sessionData]);

  const center = (transferData?.hubLat && transferData?.hubLng)
    ? {
      lat: transferData?.hubLat,
      lng: transferData?.hubLng,
    }
    : undefined;

  return (
    <aside className="sidebar">
      <HeaderLogo />
      <BookingSummary data={transferData} queryData={queryData} center={center} onOriginAndDestinationResolved={(origin: any, destination: any) => onOriginAndDestinationResolved(origin, destination)} />
    </aside>
  );
};
